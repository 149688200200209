var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "jh-navbar",
      attrs: { "data-cy": "navbar", toggleable: "md", type: "dark" },
    },
    [
      _c(
        "b-navbar-brand",
        { staticClass: "logo", attrs: { "b-link": "", to: "/" } },
        [
          _c(
            "span",
            {
              staticClass: "navbar-title",
              domProps: { textContent: _vm._s(_vm.$t("global.title")) },
            },
            [_vm._v("Portal Integratori")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-navbar-toggle",
        {
          staticClass: "jh-navbar-toggler d-lg-none",
          attrs: {
            right: "",
            href: "javascript:void(0);",
            "data-toggle": "collapse",
            target: "header-tabs",
            "aria-expanded": "false",
            "aria-label": "Toggle navigation",
          },
        },
        [_c("font-awesome-icon", { attrs: { icon: "bars" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { "is-nav": "", id: "header-tabs" } },
        [
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [
              _c("b-nav-item", { attrs: { to: "/", exact: "" } }, [
                _c(
                  "span",
                  [
                    _c("font-awesome-icon", { attrs: { icon: "home" } }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        domProps: {
                          textContent: _vm._s(_vm.$t("global.menu.home")),
                        },
                      },
                      [_vm._v("Home")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.authenticated &&
              _vm.$hasAnyAuthoritySync(["ROLE_ADMIN", "ROLE_MARKETPLACE"])
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      attrs: {
                        right: "",
                        id: "entity-menu",
                        "active-class": "active",
                        "data-cy": "entity",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "th-list" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.extra.main")
                                ),
                              },
                            },
                            [_vm._v("Extra")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync([
                        "ROLE_ADMIN",
                        "ROLE_MARKETPLACE",
                      ])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/pos-contact" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.entities.posContact")
                                    ),
                                  },
                                },
                                [_vm._v("Pos Contact")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated && _vm.$hasAnyAuthoritySync(["ROLE_ADMIN"])
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      attrs: {
                        right: "",
                        id: "entity-menu",
                        "active-class": "active",
                        "data-cy": "entity",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "th-list" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.main")
                                ),
                              },
                            },
                            [_vm._v("Entities")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync(["ROLE_ADMIN"])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/marketplace" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.entities.marketplace")
                                    ),
                                  },
                                },
                                [_vm._v("Marketplace")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync(["ROLE_ADMIN"])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/metadata" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.entities.metadata")
                                    ),
                                  },
                                },
                                [_vm._v("Metadata")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync(["ROLE_ADMIN"])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/config-endpoint" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.entities.configEndpoint"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Config Endpoint")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync(["ROLE_ADMIN"])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/config-operation" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.entities.configOperation"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Config Operation")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync(["ROLE_ADMIN"])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/config-pos-api-key" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.entities.configPosApiKey"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Config Pos Api Key")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync(["ROLE_ADMIN"])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/config-default-store-key" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.entities.configDefaultStoreKey"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Config Default Store Key")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync(["ROLE_ADMIN"])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/store-cron" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.entities.storeCron")
                                    ),
                                  },
                                },
                                [_vm._v("Store Cron")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync([
                        "ROLE_ADMIN",
                        "ROLE_MARKETPLACE",
                      ])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/marketplace-contact" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.entities.marketplaceContact"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Marketplace Contact")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync(["ROLE_ADMIN"])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/country" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.entities.country")
                                    ),
                                  },
                                },
                                [_vm._v("Country")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync(["ROLE_ADMIN"])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/integrator-api-key" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.entities.integratorApiKey"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Country")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync(["ROLE_ADMIN"])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/test-store" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.entities.testStore")
                                    ),
                                  },
                                },
                                [_vm._v("Test Store")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated &&
              _vm.$hasAnyAuthoritySync([
                "ROLE_ADMIN",
                "ROLE_POS_SYSTEM",
                "ROLE_POS_LAAS",
              ])
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      attrs: {
                        right: "",
                        id: "profile-menu",
                        "active-class": "active",
                        "data-cy": "entity",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "th-list" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.profile.main")
                                ),
                              },
                            },
                            [_vm._v("Operations")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync([
                        "ROLE_POS_SYSTEM",
                        "ROLE_POS_LAAS",
                      ])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/pos-contact" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.entities.posContact")
                                    ),
                                  },
                                },
                                [_vm._v("Pos Contact")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync([
                        "ROLE_POS_SYSTEM",
                        "ROLE_ADMIN",
                      ])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/config-pos-api-key" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.entities.configPosApiKey"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Config Pos Api Key")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync(["ROLE_ADMIN"])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/pos-test-store" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.entities.posTestStore"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Pos Test Store")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync([
                        "ROLE_POS_SYSTEM",
                        "ROLE_ADMIN",
                      ])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/config-endpoint" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.entities.configEndpoint"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Config Endpoint")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync(["ROLE_ADMIN"])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/pos-test-store" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.entities.posTestStore"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Allocate Test Store")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated &&
              _vm.$hasAnyAuthoritySync([
                "ROLE_ADMIN",
                "ROLE_MARKETPLACE",
                "ROLE_POS_SYSTEM",
                "ROLE_POS_LAAS",
              ])
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      attrs: {
                        right: "",
                        id: "operation-menu",
                        "active-class": "active",
                        "data-cy": "entity",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "th-list" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.operations.main")
                                ),
                              },
                            },
                            [_vm._v("Operations")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync([
                        "ROLE_ADMIN",
                        "ROLE_MARKETPLACE",
                      ])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/pos-implementation" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.operations.posImplementation"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Pos Implementation")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync([
                        "ROLE_ADMIN",
                        "ROLE_MARKETPLACE",
                        "ROLE_POS_SYSTEM",
                        "ROLE_POS_LAAS",
                      ])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/partner" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.operations.partner")
                                    ),
                                  },
                                },
                                [_vm._v("Partner")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync([
                        "ROLE_ADMIN",
                        "ROLE_MARKETPLACE",
                      ])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/menu-update-request" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t(
                                        "global.menu.operations.menuUpdateRequest"
                                      )
                                    ),
                                  },
                                },
                                [_vm._v("Menu Update Request")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync([
                        "ROLE_ADMIN",
                        "ROLE_MARKETPLACE",
                      ])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/store" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.operations.store")
                                    ),
                                  },
                                },
                                [_vm._v("Store")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$hasAnyAuthoritySync([
                        "ROLE_POS_SYSTEM",
                        "ROLE_POS_LAAS",
                      ])
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/laas-orders" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "asterisk" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.entities.laasOrders")
                                    ),
                                  },
                                },
                                [_vm._v("Laas Orders")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      class: {
                        "router-link-active": _vm.subIsActive("/admin"),
                      },
                      attrs: {
                        right: "",
                        id: "admin-menu",
                        "active-class": "active",
                        "data-cy": "adminMenu",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "users-cog" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.admin.main")
                                ),
                              },
                            },
                            [_vm._v("Administration")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: "/admin/email/new",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "envelope" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.admin.email")
                                ),
                              },
                            },
                            [_vm._v("Email")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: "/admin/gateway",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "road" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.admin.gateway")
                                ),
                              },
                            },
                            [_vm._v("Gateway")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: "/admin/user-management",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "users" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.admin.userManagement")
                                ),
                              },
                            },
                            [_vm._v("User management")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: "/admin/metrics",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "tachometer-alt" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.admin.metrics")
                                ),
                              },
                            },
                            [_vm._v("Metrics")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: "/admin/health",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "heart" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.admin.health")
                                ),
                              },
                            },
                            [_vm._v("Health")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: "/admin/configuration",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "cogs" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.admin.configuration")
                                ),
                              },
                            },
                            [_vm._v("Configuration")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            to: "/admin/logs",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "tasks" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.admin.logs")
                                ),
                              },
                            },
                            [_vm._v("Logs")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.openAPIEnabled
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: "/admin/docs",
                                "active-class": "active",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "book" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.admin.apidocs")
                                    ),
                                  },
                                },
                                [_vm._v("API")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.languages, function (value, key) {
                return _c(
                  "b-nav-item",
                  {
                    key: "lang-" + key,
                    class: { active: _vm.isActiveLanguage(key) },
                    staticStyle: {
                      border: "1px solid #b3b3b3",
                      "border-radius": "5px",
                      "margin-left": "0.5rem",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeLanguage(key)
                      },
                    },
                  },
                  [
                    _c("span", [
                      _c(
                        "span",
                        {
                          domProps: { textContent: _vm._s(key.toUpperCase()) },
                        },
                        [_vm._v(_vm._s(key))]
                      ),
                    ]),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "pointer",
                  class: { "router-link-active": _vm.subIsActive("/account") },
                  attrs: {
                    right: "",
                    href: "javascript:void(0);",
                    id: "account-menu",
                    "active-class": "active",
                    "data-cy": "accountMenu",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "navbar-dropdown-menu",
                      attrs: { slot: "button-content" },
                      slot: "button-content",
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "user" } }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "no-bold",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("global.menu.account.main")
                            ),
                          },
                        },
                        [_vm._v(" Account ")]
                      ),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.account ? _vm.account.login : "") +
                          "\n          "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.authenticated
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "data-cy": "settings",
                            to: "/account/settings",
                            tag: "b-dropdown-item",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "wrench" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.settings")
                                ),
                              },
                            },
                            [_vm._v("Settings")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.authenticated
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "data-cy": "passwordItem",
                            to: "/account/password",
                            tag: "b-dropdown-item",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "lock" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.password")
                                ),
                              },
                            },
                            [_vm._v("Password")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.authenticated
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "data-cy": "logout",
                            id: "logout",
                            "active-class": "active",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.logout()
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "sign-out-alt" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.logout")
                                ),
                              },
                            },
                            [_vm._v("Sign out")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.authenticated
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "data-cy": "login",
                            id: "login",
                            "active-class": "active",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openLogin()
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "sign-in-alt" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.login")
                                ),
                              },
                            },
                            [_vm._v("Sign in")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.authenticated
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "data-cy": "register",
                            to: "/register",
                            tag: "b-dropdown-item",
                            id: "register",
                            "active-class": "active",
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "user-plus" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.register")
                                ),
                              },
                            },
                            [_vm._v("Register")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }