import Vue from 'vue';

export default class AlertService {
  public showError(instance: Vue, message: string, autoHideDelay: number = 5000, params?: any) {
    const alertMessage = instance.$t(message, params);
    if (autoHideDelay == 0) {
      instance.$root.$bvToast.toast(alertMessage.toString(), {
        toaster: 'b-toaster-top-center',
        title: 'Error',
        variant: 'danger',
        solid: true,
        noAutoHide: true,
      });
    } else {
      instance.$root.$bvToast.toast(alertMessage.toString(), {
        toaster: 'b-toaster-top-center',
        title: 'Error',
        variant: 'danger',
        solid: true,
        autoHideDelay: 5000,
      });
    }
  }

  public showErrorMessage(instance: Vue, message: string, params?: any) {
    const alertMessage = message;
    instance.$root.$bvToast.toast(alertMessage.toString(), {
      toaster: 'b-toaster-top-center',
      title: 'Error',
      variant: 'danger',
      solid: true,
      autoHideDelay: 5000,
    });
  }

  public showHttpError(instance: Vue, httpErrorResponse: any, autoHideDelay: number = 5000) {
    switch (httpErrorResponse.status) {
      case 0:
        this.showError(instance, 'error.server.not.reachable', autoHideDelay);
        break;

      case 400: {
        const arr = Object.keys(httpErrorResponse.headers);
        let errorHeader: string | null = null;
        let entityKey: string | null = null;
        for (const entry of arr) {
          if (entry.toLowerCase().endsWith('app-error')) {
            errorHeader = httpErrorResponse.headers[entry];
          } else if (entry.toLowerCase().endsWith('app-params')) {
            entityKey = httpErrorResponse.headers[entry];
          }
        }
        if (errorHeader) {
          //const alertData = entityKey ? { entityName: instance.$t(`global.menu.entities.${entityKey}`) } : undefined;
          const alertData = entityKey ? { entityName: instance.$t(`${entityKey}`) } : undefined;
          this.showError(instance, errorHeader, autoHideDelay, alertData);
        } else if (httpErrorResponse.data !== '' && httpErrorResponse.data.fieldErrors) {
          this.showError(instance, httpErrorResponse.data.message, autoHideDelay);
        } else {
          this.showError(instance, httpErrorResponse.data.message, autoHideDelay);
        }
        break;
      }

      case 404:
        this.showError(instance, 'error.http.404', autoHideDelay);
        break;

      case 500:
        if (httpErrorResponse.data.detail.includes('duplicate key error')) {
          const regex = /"(.*?)"/;
          const match = httpErrorResponse.data.detail.match(regex);
          const substring = match[1];
          this.showError(instance, 'Duplicate key for ' + substring, autoHideDelay);
        } else {
          this.showError(instance, httpErrorResponse.data.message, autoHideDelay);
        }
        break;

      default:
        this.showError(instance, httpErrorResponse.data.message, autoHideDelay);
    }
  }
}
